import React, { useEffect, useState } from "react";
import SlideImage from "./SlideImage";
import SlideOffer from "./SlideOffer";
import FadeIn from 'react-fade-in';

const Slide = ({ slide, duration }) => {
  return (
    <React.Fragment>

    {slide.type === "image" && <SlideImage slide={slide} duration={duration}/>}
    <FadeIn>
    {slide.type === "offer" && <SlideOffer slide={slide} duration={duration} key={slide.id}/>}
    </FadeIn>
    </React.Fragment>

  );
};

export default Slide;
