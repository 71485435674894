import React,{useEffect, useState} from 'react';
import axios from 'axios';
import logo from './logo.svg';
import './App.css';
import Slide from './components/Slide'
import config from './config/config'


axios.defaults.baseURL = config.backendURL;
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = config.cors;

function App() {
  const [slideShow,setSlideShow] = useState([])
  const [slides, setSlides] = useState([])
  const [slide, setSlide] = useState({})
  const [slideUrl, setSlideUrl] = useState("")
  const [duration, setDuration] = useState(1)

  useEffect(() => {
    axios
      .get("api/slide-show/published")
      .then((resp) => {
        setSlideShow(resp.data[0]);
        setDuration(resp.data[0].duration)
        setSlides(resp.data[0].Slides)
        
        })
      .catch((err) => {
        console.log(err)
      });
      setInterval(() => window.location.reload(), 600000);

  }, []);

  useEffect(() => {
    slideThrough(slides, duration)

  }, [slides]);

  const slideThrough = (slides, durationTemp) => {

      let index = 0
      setInterval (function () {
        const slide = slides[index]
        if(slide !== undefined && slide.url !== undefined) {
          setSlideUrl(slide.url);
          setSlide(slide)
        }
        if (index === slides.length -1) {
          index = 0
       } else {
        index += 1;
       }
     },durationTemp * 1000);


    }


  

    
  

  return (
    <div className="App">
      
      <Slide slide={slide} slideUrl={slideUrl} duration={duration}/>
      
    </div>
  );
}

export default App;
