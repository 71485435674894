import React, { useEffect, useState } from "react";
import config from "../config/config";

const SlideOffer = ({ slide, duration }) => {

    const [discountPercentage, setDiscountPercentage] = useState(0);
    

    useEffect(()  => {
        setDiscountPercentage(Math.round((1-parseFloat(slide.offer_price_offer)/parseFloat(slide.offer_price_official))*100))
    }, [slide])

  return (
    <div className="offer" key="slide.id">
    {/*<div className="offer" style={{  animation: `pulse ${duration}s infinite`   }} key="slide.id">*/}
    
      <div className="discount-percentage-wrapper">
        <div className="discount-percentage">{discountPercentage}%</div>
      </div>

      <div
        className="offer-image"
        style={{
          backgroundImage: `url(${config.backendURL}/${slide.url})`,
        }}
      />
      <div className="content-box">
        <span className="offer-name">{slide.offer_name}</span>
        <span className="offer-price-official">
          {slide.offer_price_official}
        </span>
        <span className="offer-price-discount">{slide.offer_price_offer}</span>
      </div>
    </div>
  );
};

export default SlideOffer;
