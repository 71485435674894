import React, { useEffect, useState } from "react";
import config from "../config/config";

const SlideImage = ({slide, duration }) => {
  return (
    <div
      className="slide"
      style={{
        backgroundImage: `url(${config.backendURL}/${slide.url})`,
        backgroundPosition: `center`,
        backgroundSize: `${slide.image_mode}`,
        backgroundRepeat: `no-repeat`,
        width: `100vw`,
        height: `100vh`,
        animation: `pulse ${duration}s infinite`
      }}
    ></div>
  );
};

export default SlideImage;
